import React, { FC, useState } from "react"
import { observer } from "mobx-react-lite"
import { ImageBackground, Platform, ViewStyle, useWindowDimensions } from "react-native"
import { Box, HStack, Icon, IconButton, Select, StatusBar, Text, VStack, Image, Button, Spacer, Modal, View, ScrollView, Menu, Pressable, HamburgerIcon, Divider, CheckIcon } from "native-base"
import { MaterialIcons, FontAwesome5, MaterialCommunityIcons, Ionicons } from "@expo/vector-icons";

import { HomeStackParamList } from "../navigators"
import { DrawerScreenProps } from "@react-navigation/drawer"
import { useStores } from "../models"
import * as FileSystem from 'expo-file-system';
import * as ImagePicker from 'expo-image-picker';
import * as ImageManipulator from 'expo-image-manipulator';
import SignatureCanvas from 'react-signature-canvas'
import SignatureScreen from 'react-native-signature-canvas';
import { convertImageToBase64 } from "../utils/general";
import { ModalFull } from "../components";
import { customApp } from "../config";
import * as Storage from "../utils/storage"
import { calcularVelocidadConexion } from "../utils/general"

export const SettingsScreen: FC<DrawerScreenProps<HomeStackParamList, "Settings">> = observer(function SettingsScreen(props) {
  const { authenticationStore, formsStore, statusStore, itemsStore } = useStores();
  const route = props.route;
  const navigation = props.navigation
  const user = authenticationStore.userInfo.user;
  const source = user.hasProfile ? { uri: user.profileUrl } : require("../../assets/images/logo_circle.png");
  const subscriptions = formsStore.mySubscriptions().subscriptions;
  const [modalVisible, setModalVisible] = React.useState(false);
  const [speedTest, setSpeedTest] = React.useState("");
  console.log("ACA PASE2")

  const signRef = React.useRef<SignatureCanvas>(null);
  const clear = () => {
    Platform.OS == "web" ? signRef.current?.clear() : signRef.current.clearSignature();
  }


  const getFromCanvas = async () => {
    if (Platform.OS == "web") {
      let uriBase64 = signRef.current?.getCanvas()
        .toDataURL("image/jpeg", 1);
      let resizeTo = [{ resize: { width: 400 } }];
      uriBase64 = await ImageManipulator.manipulateAsync(
        uriBase64, resizeTo,
        { compress: 0.7, format: ImageManipulator.SaveFormat.JPEG }
      );
      save(uriBase64.uri);
    } else {
      signRef.current.readSignature();
    }
  }
  const save = async (uri) => {
    authenticationStore.saveSignCache(uri);
    setModalVisible(false);
  }
  const putStamp = () => {
    if (Platform.OS == "web")
      signRef.current?.fromDataURL(authenticationStore.userInfo.user.stampCache);
    else
      convertImageToBase64(authenticationStore.userInfo.user.stampCache, signRef.current.loadDataURL);

  }
  const chooseFile = async (destination: "profile" | "stamp" = "profile") => {
    let result: ImagePicker.ImagePickerResult = await ImagePicker.launchImageLibraryAsync({
      allowsEditing: true,
      quality: 1,
    });

    if (!result.cancelled) {
      let resizeTo = [{ resize: { width: 400 } }];
      result = {
        ...await ImageManipulator.manipulateAsync(
          //@ts-ignore
          result.uri, resizeTo,
          { compress: 0.7, format: ImageManipulator.SaveFormat.JPEG }
        ), cancelled: false
      };
      switch (destination) {
        case "stamp":
          authenticationStore.saveStampCache(result.uri);
          break;
        case "profile":
          if (Platform.OS == "web") {
            //@ts-ignore
            authenticationStore.mustUploadProfileImage(true, result.uri)
          } else {
            await FileSystem.copyAsync({
              //@ts-ignore
              from: result.uri,
              //@ts-ignore
              to: `${FileSystem.documentDirectory}profile-${authenticationStore.userInfo.user.id}`.concat(result.uri.substring(result.uri.lastIndexOf(".")))
            }
            );
          }
          break;
      }
    } else {
      alert('You did not select any image.');
    }
  };

  

  const bgTask = authenticationStore.userInfo.user.bgTask;
  let width = statusStore.windowDimensions.width - 15;
  let height = (statusStore.windowDimensions.height - 150) > width ? width * 0.66 : (statusStore.windowDimensions.height - 150);
  const screen = useWindowDimensions();

  React.useEffect(() => {
    width = statusStore.windowDimensions.width - 15;
    height = (statusStore.windowDimensions.height - 150) > width ? width * 0.66 : (statusStore.windowDimensions.height - 150);
    
  }, [screen])

 

  const style = `.m-signature-pad {box-shadow: none; margin-top:0px;margin-left:1px; } 
  .m-signature-pad--body {left:0}
  .m-signature-pad--footer {display: none; margin: 0px;}
  body,html {
  width: ${width.toFixed(0)}px; height: ${height.toFixed(0)}px;}`;

  let leftButton = <IconButton icon={<Icon size="lg" as={MaterialIcons} name="menu" color="white" />} onPress={() => navigation.openDrawer()} />;
  if (route.params?.fromForm) {
    leftButton = <IconButton icon={<Icon size="lg" as={MaterialIcons} name="arrow-back" color="white" />} onPress={() => navigation.goBack()} />;
  }
   if (route.params?.navigateTo) {
     leftButton = <IconButton icon={<Icon size="lg" as={MaterialIcons} name="arrow-back" color="white" />} onPress={() => navigation.navigate(route.params?.navigateTo)} />;
  }
  return (<>
    <StatusBar backgroundColor={"#3700B3"} barStyle="light-content" />
    <Box safeAreaTop bg="primary.400" />
    <HStack bg="primary.400" px="1" py="3" justifyContent="space-between" alignItems="center" w="100%">
      <HStack alignItems="center">
        {leftButton}
        <Text color="white" fontSize="18" >
          Configuraciones
        </Text>
      </HStack>
    </HStack>
    <VStack bg="white" flex={1}><ScrollView>
      <HStack bgColor={"light.100"} px="1" py="2" my="1" justifyContent="flex-start" alignItems="center" w="100%" >
        <Icon as={FontAwesome5} name="user-circle" size="sm" color="secondary.400" marginX={5} />
        <Text fontSize={"16"}>Perfil de Usuario</Text>
      </HStack>
      <HStack px="1" py="2" justifyContent="flex-start" alignItems="center" w="100%" paddingLeft={5} >
        <ImageBackground source={source} style={{ width: 80, height: 80, justifyContent: "center", alignItems: "center", }} resizeMethod="resize"  >
          <IconButton icon={<Icon as={MaterialIcons} name="camera-alt" size="sm" color="white" variant="outline" />} onPress={() => chooseFile()} />
        </ImageBackground>
        <VStack marginLeft={5} width="70%" alignItems="center" borderBottomWidth={1} borderBottomColor="light.300" paddingBottom={10}>
          <Text fontSize={"md"} style={{ textAlign: "center" }} bold>
            {user?.firstName} {user?.lastName} <IconButton icon={<Icon as={MaterialIcons} name="camera-alt" size="sm" color="white" />} onPress={() => chooseFile()} />
          </Text>
          <Text fontSize={"xs"} display={customApp.user?"none":"flex"}>{user?.email} </Text>
        </VStack>
      </HStack>
      <HStack display={"none"} bgColor={"light.100"} px="1" py="2" marginTop="5" justifyContent="flex-start" alignItems="center" w="100%"  >
        <Icon as={MaterialIcons} name="first-page" size="sm" color="secondary.400" marginX={5} />
        <Text fontSize={"16"}>Indique sección de inicio</Text>
      </HStack>
      <HStack display={"none"} px="1" py="1" justifyContent="flex-start" alignItems="center" paddingLeft={5} >
        <Select minWidth="200" width={statusStore.windowDimensions.width * 0.95}
          selectedValue={authenticationStore.userInfo.user.initialRoute}
          accessibilityLabel="Sección Inicio"
          placeholder="Indique punto de inicio"
          _selectedItem={{
            bg: "secondary.400",
          }} mt={1} onValueChange={itemValue => authenticationStore.updateinitialRoute(itemValue)}>
          <Select.Item label="Configuraciones" value="Settings" />
          <Select.Item label="Tareas" value="Tasks" />
          {subscriptions?.map((dept: any) => {
            return (
              <Select.Item key={dept.name} label={dept.name} value={dept.id} />
            )
          })
          }
        </Select>
      </HStack>
      <HStack bgColor={"light.100"} px="1" py="2" marginTop="5" justifyContent="flex-start" alignItems="center" w="100%"  >
        <Icon as={FontAwesome5} name="stamp" size="sm" color="secondary.400" marginX={5} />
        <Text fontSize={"16"}>Sello</Text>
        <Spacer></Spacer>
        <IconButton display={user.stampCache == undefined ? "none" : "flex"} size="md" colorScheme="danger" onPress={() => authenticationStore.saveStampCache(undefined)} icon={<Icon as={Ionicons} name="trash" size="md" />} />
        <IconButton icon={<Icon as={MaterialIcons} name="photo-library" size="md" variant="outline" />} onPress={() => chooseFile("stamp")} />
      </HStack>
      <VStack px="1" py="1" justifyContent="flex-start" alignItems="center" paddingLeft={5} >
        {user.stampCache ?
          <HStack width="100%" >
            <Image alt={"stampCache"} key={"stampCache"} source={{ uri: user.stampCache }} width="100%" height="150"
              style={{ height: 150, flex: 1, resizeMode: "contain", backgroundColor: "#fff", marginTop: 2 }} />
          </HStack>
          : <Button variant={"ghost"} width={"full"} p="2" borderRadius={0} onPress={() => chooseFile("stamp")} rightIcon={<Icon as={MaterialIcons} name="photo-library" size="lg" />}>
            <Text color="secondary.400">No has cargado ninguna imagen de sello. </Text>
          </Button>
        }
      </VStack>
      <HStack bgColor={"light.100"} px="1" py="2" marginTop="5" justifyContent="flex-start" alignItems="center" w="100%"  >
        <Icon as={FontAwesome5} name="signature" size="sm" color="secondary.400" marginX={5} />
        <Text fontSize={"16"}>Firma Guardada</Text>
        <Spacer></Spacer>
        <IconButton display={user.signCache == undefined ? "none" : "flex"} size="md" colorScheme="danger" onPress={() => authenticationStore.saveSignCache(undefined)} icon={<Icon as={Ionicons} name="trash" size="md" />} />
        <IconButton icon={<Icon as={MaterialCommunityIcons} name="signature-freehand" size="md" variant="outline" />} onPress={() => setModalVisible(true)} />
      </HStack>
      <VStack px="1" py="1" justifyContent="flex-start" alignItems="center" paddingLeft={5} >
        {user.signCache ?
          <HStack width="100%" >
            <Image alt={"signCache"} key={"signCache"} source={{ uri: user.signCache }} width="100%" height="150"
              style={{ height: 150, flex: 1, resizeMode: "contain", backgroundColor: "#fff", marginTop: 2 }} />
          </HStack>
          :
          <Button variant={"ghost"} width={"full"} p="2" borderRadius={0} onPress={() => setModalVisible(true)} rightIcon={<Icon as={MaterialCommunityIcons} name="signature-freehand" size="lg" />}>
            <Text color="secondary.400">No has generado tu firma de perfil aún. </Text>
          </Button>
        }
      </VStack>
      {Platform.OS == "web" ?
        <HStack bgColor={"light.100"} px="1" py="2" marginTop="5" justifyContent="flex-start" alignItems="center" w="100%" flexWrap="wrap" >
        <Icon as={MaterialCommunityIcons} name="database" size="sm" color="secondary.400" marginX={5} />
        <Text fontSize={"16"} flexWrap="wrap" textBreakStrategy="balanced">Consumo Almacenamiento </Text>
        <Spacer/>
        <Text fontSize={"16"} flexWrap="wrap" textBreakStrategy="balanced">{Storage.localStorageSpace()}</Text>
        </HStack>
        :
        <>
          <HStack bgColor={"light.100"} px="1" py="2" marginTop="5" justifyContent="flex-start" alignItems="center" w="100%" flexWrap="wrap" >
            <Icon as={MaterialIcons} name="settings-backup-restore" size="sm" color="secondary.400" marginX={5} />
            <Text fontSize={"16"} flexWrap="wrap" textBreakStrategy="balanced">Subir  Items Cerrados </Text>
            {bgTask.uploadItemsNetwork == "dataOrWifi" ? <>
              <Icon as={MaterialCommunityIcons} name="wifi" color={"success.600"} size="sm" marginLeft={1} />
              <Icon as={MaterialCommunityIcons} name="signal-off" color={"success.600"} size="sm" marginLeft={1} />
            </>
              :
              <>
                <Icon as={MaterialCommunityIcons} name="wifi" color={"success.600"} size="sm" marginLeft={1} />
                <Icon as={MaterialCommunityIcons} name="signal-off" size="sm" marginLeft={1} />
              </>
            }
            <Spacer />
            {bgTask.uploadItems ?
              <Icon as={MaterialCommunityIcons} name="cog-play" color={"success.600"} size="md" marginLeft={5} />
              :
              <Icon as={MaterialCommunityIcons} name="cog-stop" color={"danger.400"} size="md" marginLeft={5} />
            }
            <Menu closeOnSelect={false} w="200" onOpen={() => console.log("opened")} onClose={() => console.log("closed")} placement="top left" trigger={triggerProps => {
              return <Pressable {...triggerProps}>
                <Icon as={MaterialIcons} name="more-vert" size="md" color="primary.400" marginLeft={5} />
              </Pressable>;
            }}>
              <Menu.OptionGroup defaultValue={bgTask.uploadItems.toString()} title="Estado" type="radio">
                <Menu.ItemOption value={"true"} onPress={() => authenticationStore.updateBgTask({ uploadItems: true })}>Activo</Menu.ItemOption>
                <Menu.ItemOption value={"false"} onPress={() => authenticationStore.updateBgTask({ uploadItems: false })}>Inactivo</Menu.ItemOption>
              </Menu.OptionGroup>
              <Divider mt="3" w="100%" />
              <Menu.OptionGroup defaultValue={bgTask.uploadItemsNetwork} title="Conectividad" type="radio" >
                <Menu.ItemOption pr="4" value="dataOrWifi" onPress={() => authenticationStore.updateBgTask({ uploadItemsNetwork: "dataOrWifi" })}>Sincronizar con Datos Moviles o Wifi</Menu.ItemOption>
                <Menu.ItemOption pr="4" value="onlyWifi" onPress={() => authenticationStore.updateBgTask({ uploadItemsNetwork: "onlyWifi" })}>Sincronizar solo con Wifi</Menu.ItemOption>
              </Menu.OptionGroup>
            </Menu>
          </HStack>
          <VStack px="1" py="1" justifyContent="flex-start" alignItems="center" paddingLeft={5} >
            <HStack alignItems="center" justifyContent="space-around">
              <Icon as={MaterialCommunityIcons} name="clock-start" size="sm" marginX={5} />
              <Select width="150"
                selectedValue={bgTask.uploadItemsFromTime}
                onValueChange={nextValue => authenticationStore.updateBgTask({ uploadItemsFromTime: nextValue })}
                _selectedItem={{
                  bg: "cyan.600",
                  endIcon: <CheckIcon size={4} />
                }} accessibilityLabel="Select a position for Menu">
                {Array.from({ length: 24 }, (value, index) => <Select.Item disabled={index <= parseInt(bgTask.uploadItemsToTime)} key={`item${index}`} label={`00${index}`.substr(-2)} value={`00${index}`.substr(-2)} />)}
              </Select>
              <Select key="selectTo" width="150px"
                selectedValue={bgTask.uploadItemsToTime}
                onValueChange={nextValue => authenticationStore.updateBgTask({ uploadItemsToTime: nextValue })}
                _selectedItem={{
                  bg: "cyan.600",
                  endIcon: <CheckIcon size={4} />
                }} accessibilityLabel="Select a position for Menu">
                {Array.from({ length: 24 }, (value, index) => <Select.Item disabled={index >= parseInt(bgTask.uploadItemsFromTime)} key={`item${index}`} label={`00${index}`.substr(-2)} value={`00${index}`.substr(-2)} />)}
              </Select>
              <Icon as={MaterialCommunityIcons} name="clock-end" size="sm" marginX={5} />
            </HStack>
            <HStack shadow={1} m="1" p="1" alignItems={"center"} width="70%">
              <Icon name="warning-outline" mr="1" color={"warning.400"} as={Ionicons} size="lg" />
              <Text italic flexWrap="wrap">Este proceso se activa al dejar de utilizar Field y que la aplicación quede en segundo plano, así no interferir en la operatoria.</Text>
            </HStack>
          </VStack>
          <HStack bgColor={"light.100"} px="1" py="2" marginTop="5" justifyContent="flex-start" alignItems="center" w="100%" flexWrap="wrap" >
            <Icon name="calendar-outline" as={Ionicons} size="sm" color="secondary.400" marginX={5} />
            <Text fontSize={"16"} flexWrap="wrap" textBreakStrategy="balanced">Recordatorio de Tareas próximas </Text>
            <Spacer />
            {bgTask.taskreminder ?
              <Icon as={MaterialCommunityIcons} name="play" color={"success.600"} size="md" variant="outline" />
              :
              <Icon as={MaterialCommunityIcons} name="stop" color={"danger.400"} size="md" variant="outline" />
            }

            <Menu closeOnSelect={true} w="200" onOpen={() => console.log("opened")} onClose={() => console.log("closed")} placement="top left" trigger={triggerProps => {
              return <Pressable {...triggerProps}>
                <Icon as={MaterialIcons} name="more-vert" size="md" color="primary.400" marginLeft={5} />
              </Pressable>;
            }}>
              <Menu.OptionGroup defaultValue={bgTask.taskreminder.toString()} title="Estado" type="radio">
                <Menu.ItemOption value={"true"} onPress={() => authenticationStore.updateBgTask({ taskreminder: true })}>Activo</Menu.ItemOption>
                <Menu.ItemOption value={"false"} onPress={() => authenticationStore.updateBgTask({ taskreminder: false })}>Inactivo</Menu.ItemOption>
              </Menu.OptionGroup>              
            </Menu>
            
          </HStack>
          <HStack alignItems={"center"} marginBottom="10" p="5">
            <Text>Notificar </Text>
            <Select key="selectTo" width="150px"
              selectedValue={bgTask.taskreminderTime}
              onValueChange={nextValue => authenticationStore.updateBgTask({ taskreminderTime: nextValue })}
              _selectedItem={{
                bg: "cyan.600",
                endIcon: <CheckIcon size={4} />
              }} accessibilityLabel="Select a position for Menu"
            >
              <Select.Item key="taskreminderTime1" label={" 5 minutos antes"} value="5" />
              <Select.Item key="taskreminderTime2" label={" 30 antes"} value="30" />
              <Select.Item key="taskreminderTime1" label={" 1 Hora antes"} value="60" />

            </Select>

          </HStack>
        </>
      }
      <HStack bgColor={"light.100"} px="1" py="2" marginTop="5" justifyContent="flex-start" alignItems="center" w="100%" flexWrap="wrap" >
        <Icon as={MaterialIcons} name="speed" size="sm" color="secondary.400" marginX={5} />
        <Text fontSize={"16"} flexWrap="wrap" textBreakStrategy="balanced">Velocidad Conexión al Servidor </Text>
        <IconButton icon={<Icon as={MaterialIcons} name="refresh" size="md" variant="outline" />} onPress={() => calcularVelocidadConexion(setSpeedTest,statusStore)} />
        <Spacer />
        <Text fontSize={"16"} flexWrap="wrap" textBreakStrategy="balanced">{speedTest}Mbps</Text>
      </HStack>
    </ScrollView>
    </VStack >
    <ModalFull
      visible={modalVisible}
      title="Firmar"
      closeButton_onPress={() => { setModalVisible(!modalVisible); }}
      rightButtons={authenticationStore.userInfo.user.stampCache && <IconButton icon={<Icon as={FontAwesome5} name="stamp" color={"white"} size="md" variant="outline" />} onPress={putStamp} />}
      footer={<>
        <Button colorScheme="blue" width={"1/2"} p="2" borderRadius={0} onPress={getFromCanvas} leftIcon={<Icon as={Ionicons} name="checkmark" size="lg" />}>
          Aceptar
        </Button><Button colorScheme="blue" p="2" borderRadius={0} width={"1/2"} onPress={clear} leftIcon={<Icon as={Ionicons} name="checkmark" size="lg" />}>
          Limpiar
        </Button>
      </>
      }
    >
      <View width={width || 350} alignSelf={"center"} borderWidth={1}>
        {Platform.OS == "web" ?
          <SignatureCanvas
            ref={signRef}
            backgroundColor="#ffff"
            minWidth={1}
            maxWidth={5}
            canvasProps={
              {
                width: width || 350,
                height: height || 200,
              }
            }

          />
          : <View style={{ height: height, }}>
            <SignatureScreen
              ref={signRef}
              onOK={save}
              webStyle={style}
            />
          </View>
        }
      </View>
    </ModalFull>
  </>
  )
})

const $root: ViewStyle = {
  flex: 1,
}

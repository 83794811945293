const getElementValue = (item, elementId, parentId, recordIdx) => {
    if (item[elementId])
        return item[elementId]

    if (item.payload && item.payload[elementId]?.value != undefined)
        return item.payload[elementId];
    if (recordIdx != undefined && item.payload && item.payload[parentId]?.value[recordIdx] && item.payload[parentId]?.value[recordIdx][elementId])
        return item.payload[parentId]?.value[recordIdx][elementId]
    if (parentId && item.payload && item.payload[parentId]?.activeRecord?.record != undefined && item.payload[parentId].activeRecord.record[elementId])
        return item.payload[parentId].activeRecord.record[elementId]
    return undefined;
}

const walkConditions = (rule, item, parentId, recordIdx) => {
    try {
        var valid = undefined;
        if (rule.type === "operator") {
            if (rule.rules.length < 2) {
                valid = false;
                return valid;
            } else {
                if (rule.operator === "or") {
                    rule.rules.forEach(function (subRule) {
                        if (valid === true) return;
                        valid = walkConditions(subRule, item, parentId, recordIdx);
                    });
                    return valid;
                }

                if (rule.operator === "and") {
                    rule.rules.forEach(function (subRule) {
                        if (valid === false) return;
                        valid = walkConditions(subRule, item, parentId, recordIdx);
                    });
                    return valid;
                }
            }
        } else if (rule.type === "field") {
            let ruleValue = rule.value;
            let elementValue = getElementValue(item, rule.fieldId, parentId, recordIdx);
            if (elementValue != undefined)
                elementValue = JSON.parse(JSON.stringify(elementValue));
            if (
                rule.operator !== "existe" &&
                rule.operator !== "!existe" &&
                (ruleValue === undefined ||
                    ruleValue === null ||
                    !elementValue)
            ) {
                return false;
            }
            if (rule.operator == "!existe" && elementValue == undefined) {
                return true;
            }

            if ((elementValue.type === "select" || elementValue.type === "multiple")
                && elementValue.value.includes("|") && ruleValue.includes("|")
                && /\bOTHER\b/.test(elementValue.value)
                && /\bOTHER\b/.test(ruleValue)
            ) {
                ruleValue = "other";
                elementValue.value = "other";
            }

            ruleValue =
                typeof ruleValue === "string" ? ruleValue.toLowerCase() : ruleValue;

            if (
                elementValue.type == "checkbox" && elementValue.value != true
            ) {
                elementValue.value = false;
            }

            if (
                elementValue.type === "checkbox" &&
                (ruleValue === "true" || ruleValue === "si" || ruleValue === true)
            ) {
                ruleValue = true;
            }
            if (elementValue.type === "number") {
                ruleValue = parseFloat(ruleValue);

            }

            if (elementValue.type === "select" || elementValue.type === "multiple") {
                var value = parseFloat(ruleValue);
                if (!isNaN(ruleValue) && !isNaN(value)) ruleValue = value;
            }

            if (
                elementValue.type === "checkbox" &&
                (ruleValue === "false" || ruleValue === "no" || ruleValue === false)
            ) {
                ruleValue = false;
            }
            
            switch (rule.operator) {
                case "=":
                    if (!elementValue.value && !ruleValue) {
                        return true;
                    }

                    var currentValue =
                        typeof elementValue.value === "string"
                            ? elementValue.value.toLowerCase()
                            : elementValue.value;
                    return currentValue == ruleValue;
                case "!=":
                    var currentValue =
                        typeof elementValue.value === "string"
                            ? elementValue.value.toLowerCase()
                            : elementValue.value;
                    return currentValue != ruleValue;
                case ">":
                    var value = parseFloat(elementValue.value);
                    if (isNaN(value)) return false;
                    return value > ruleValue;
                case "<":
                    var value = parseFloat(elementValue.value);
                    if (isNaN(value)) return false;
                    return value < ruleValue;
                case "<=":
                    var value = parseFloat(elementValue.value);
                    if (isNaN(value)) return false;
                    return value <= ruleValue;
                case ">=":
                    var value = parseFloat(elementValue.value);
                    if (isNaN(value)) return false;
                    return value >= ruleValue;
                case "existe":
                    return elementValue.value === null ||
                        elementValue.value === false ||
                        elementValue.value === undefined ||
                        elementValue.value === ""
                        ? false
                        : true;
                case "!existe":
                    return elementValue.value === null ||
                        elementValue.value === false ||
                        elementValue.value === undefined ||
                        elementValue.value === ""
                        ? true
                        : false;
                default:
                    return false;
            }
        }
    } catch (e) {
        console.log("conditionsIsGo", "Error", e); return false;
    }
}

const conditionTriggers = (row, item, parentId, recordIdx) => {
    try {
        if (!row.valid) return false;

        var valid = row.conditions.reduce(function (prev, rule) {
            if (prev === false) return prev;
            return walkConditions(rule, item, parentId, recordIdx);
        }, true);

        return valid;
    } catch (e) {
        return false;
    }
}

function conditionIsGo(conditions, item, parentId = null, recordIdx = null) {
    if (!conditions) {
        return;
    }
    const logicOptions = conditions.filter(function (row) {
        return row.conditions && conditionTriggers(row, item, parentId, recordIdx);
    }).reduce(function (opts, row) {
        return opts.concat(row.options);
    }, []);
    const filteredOptions = conditions.reduce((opts, row) => {
        if (row && row.filterOptions ) {
            row.filterOptions.forEach(filterOptions=>{
                const regex = /\$\{([^|}]+)(?:\|[^}]*)?\}/g;
                let mask = filterOptions.mask;
                mask = mask.replace(regex, (match, fieldId) => {
                    console.log(`Hash encontrado: ${fieldId}`); // Puedes registrar el hash antes de reemplazarlo
                    return `${getElementValue(item, fieldId, parentId, recordIdx).value.split("|")[0].replace("*","")}`; // Mantiene el formato `${}` con el nuevo valor                
                });
                for (const option of row.options) {
                    if (option.includes(mask)) {
                        opts.push(option);
                    }
                }
            })
            

        }
        return opts;
    }, []);
    return logicOptions.concat(filteredOptions);
}

export { conditionIsGo };

import { types } from "mobx-state-tree"

const t = types;
export const AnyJsonValue = types.union(
    types.string,
    types.number,
    types.integer,
    types.Date,
    types.boolean,
    types.map(types.late(() => AnyJsonValue)),
    types.array(types.late(() => AnyJsonValue)),
    types.undefined,
    types.null
);


export const activeRecordModel = t.model({
    record: t.maybeNull(types.map(t.model({
        type: t.string,
        value: t.maybeNull(AnyJsonValue),
        valid: t.maybeNull(t.boolean),
        url: t.maybeNull(t.string),
        inactiveGroup: t.maybeNull(t.boolean),
        templateData: t.maybeNull(AnyJsonValue),
    }))),
    idx: t.maybeNull(t.integer),
});

export const payloadModel = t.model({
    type: t.string,
    value: t.maybeNull(AnyJsonValue),
    url: types.maybeNull(t.string),
    valid: t.maybeNull(t.boolean),
    readOnly: t.maybeNull(t.boolean),
    inactiveGroup: t.maybeNull(t.boolean),
    activeRecord: t.maybeNull(activeRecordModel),
});

export const phoneInformationModel = t.model({
    model: types.maybeNull(t.string),
    systemVersion: types.maybeNull(t.string),
    deviceId: types.maybeNull(t.string),
    package: types.maybeNull(t.string),
    version: types.maybeNull(t.string),
    versionCode: types.maybeNull(t.string),
})

export const itemModel = t.model({
    _startedAt: types.maybeNull(t.Date),
    payload: types.maybeNull(types.map(payloadModel)),
    _timeOffset: types.maybeNull(t.number),
    memberId: t.string,
    membersId:types.maybeNull(t.array(t.string)),
    tenantId: types.maybeNull(t.string),
    deptId: t.string,
    formId: t.string,
    taskId: types.maybeNull(t.string),
    formType: types.maybeNull(t.string),
    status: types.maybeNull(t.string),
    statusPTW: types.maybeNull(t.string),
    stepNumber: types.maybeNull(t.number),
    readOnly: types.maybeNull(t.boolean),
    _rnd: types.maybeNull(t.string),
    id: types.maybeNull(t.string),
    prevId: types.maybeNull(t.string),
    closedAt: types.maybeNull(t.Date),
    phoneInformation: types.maybeNull(phoneInformationModel),
})

export const IntegrationsOfflineByUserModel = types.model({
    userId: types.optional(types.string, ""),
    id: types.identifier,
    definition: t.maybeNull(AnyJsonValue),
    response: t.maybeNull(t.string),
    lastSync: types.number
})

export const IntegrationsCacheByUserModel = types.model({
    userId: types.optional(types.string, ""),
    id: types.identifier,
    dynamicParams: t.maybeNull(t.string),
    response: t.maybeNull(t.string),
    lastSync: types.number
})

export const LoadingInfoModel = types.model({
    title: types.optional(types.string, ""),
    iconName: types.maybeNull(types.string),
    iconFamily: types.maybeNull(types.string),
    spinner: types.maybeNull(types.boolean),
    id: types.number
})

export const taskPayloadModel = t.model({
    id: types.maybeNull(t.string),
    key: types.maybeNull(t.string),
    value: types.maybeNull(t.string),
    fieldId: types.maybeNull(t.string),
    onlyWeb: types.maybeNull(t.boolean),
});


export const taskWeekDays = t.model({
    "sunday": t.optional(t.boolean, false),
    "monday": t.optional(t.boolean, false),
    "tuesday": t.optional(t.boolean, false),
    "wednesday": t.optional(t.boolean, false),
    "thursday": t.optional(t.boolean, false),
    "friday": t.optional(t.boolean, false),
    "saturday": t.optional(t.boolean, false),
});

export const repeatDataModel = t.model({
    repeatEnds: types.maybeNull(t.boolean),
    repeatEvery: types.maybeNull(t.number),
    repeatMonthDates: types.maybeNull(types.array(t.string)),
    repeatTill: types.maybeNull(t.string),
    repeatType: types.maybeNull(t.string),
    repeatWeekDays: types.maybeNull(taskWeekDays),
})

export const taskModel = t.model({
    id: t.string,
    allDay: t.boolean,
    client: AnyJsonValue,
    clientId: t.string,
    color: types.maybeNull(t.string),
    description: types.maybeNull(t.string),
    endDate: types.maybeNull(t.string),
    formId: t.string,
    formName: types.maybeNull(t.string),
    deptId: types.maybeNull(t.string),
    name: t.string,
    payload: types.maybeNull(t.array(taskPayloadModel)),
    payloadHidden: AnyJsonValue,
    repeat: t.boolean,
    repeatData: types.maybeNull(repeatDataModel),
    startDate: t.string,
    tenantId: t.string,
    _createdAt: types.maybeNull(t.string),
})
export const tasksByUserModel = types.model({
    userId: types.identifier,
    tasks: types.array(taskModel),
    lastSync: types.number
})

export type BGTASK_TYPE = {
    uploadItems?: boolean,
    uploadItemsNetwork?: string,
    uploadItemsFromTime?: string,
    uploadItemsToTime?: string,
    taskreminderTime?:string,
    taskreminder:boolean
}

export type USER_TYPE = {
    id: string,
    firstName: string,
    lastName: string,
    email: string,
    tenantId: string,
    profileUrl?: string,
    hasProfile: boolean,
    initialRoute: string,
    pushToken?: string,
    signCache?: string,
    stampCache?: string,
    memoryLow: boolean,
    SAML: boolean,
    bgTask?: BGTASK_TYPE,
}

export type USERINFO_TYPE = {
    userToken: string,
    deviceId: string,
    model: string,
    systemVersion: string,
    user: USER_TYPE
}

export type FORMFIELD_TYPE = {
    id: string,
    name?: string,
    label?: string,
    type: string,
    templateData: any,
    validation: any,
}

export type KEYVALUE_TYPE = {
    key:string,
    value?:string

}
export type WORKFLOW_STEP_TYPE = {
    name?:string,
    checkStepON: string, //fieldId of checkbox that enable group of step
    member: string, // fieldId of select where say user is owner of step
}

export type WORKFLOW_TYPE = {
    geo?:string, //fieldId
    reject?:string, //fieldId
    steps_approved?:number, //number of step idx
    allowChange?:string[], //list of fields id
    steps: WORKFLOW_STEP_TYPE[],
    requestby?: string, //fieldId of member that started the workflow
    scheduledDate?:string, //date of work
    step0_name?:string,
}
export type FORM_TYPE = {
    id: string,
    name?: string,
    description?: string,
    subscribed?:boolean,
    _createdAt:any,
    departmentId:string,
    tenantId:string,
    pages:FORMPAGE_TYPE[],
    validate_instances?:number,
    validate_type:string,
    metadata?:KEYVALUE_TYPE[]
    validate_pages:any[],
    validate_users:any[],
    webHooks:any[],
    workflow:WORKFLOW_TYPE,
    type: string,
    validate_defined:any[],
    measurement:any[]    
}

export type FORMPAGE_TYPE = {
    id: string,
    requires?: any[],
    fields: FORMFIELD_TYPE[],
}

export type PAYLOAD_TYPE = {
    type?: string,
    value?: any,
    url?:string,
    valid?: boolean,
    readOnly?:boolean,
    activeRecord?: {
        idx: number,
        record: any
    } | undefined

}


export type LOADINGINFO_TYPE = {
    id?: number,
    title?: string,
    iconName?: string,
    iconFamily?: string,
    spinner?: boolean | undefined
}


export enum ITEM_STATUS {
    draft = "draft", closed = "closed", sent = "sent"
}
import React, { FC, FunctionComponent } from "react"
import { observer } from "mobx-react-lite"

import { SwipeListView } from 'react-native-swipe-list-view';
import { Pressable, } from "react-native"

import { WebView } from 'react-native-webview';
import { WebView as WebViewForWeb } from 'react-native-web-webview';


import {
    AlertDialog, Button,
    Spacer
} from "native-base"


import {
    StatusBar, Text, Badge, BadgeText,
    View, Button as Button2, Box, Icon,
    Input, InputSlot, InputIcon, InputField,
    ButtonText, ButtonIcon, VStack, HStack,
    SearchIcon,
    Actionsheet,
    ScrollView,
    ActionsheetContent, ActionsheetDragIndicatorWrapper, ActionsheetDragIndicator,
    ActionsheetItem, ActionsheetBackdrop,
    ActionsheetItemText,
    ActionsheetIcon,
} from "@gluestack-ui/themed";

import { MaterialIcons, Ionicons, MaterialCommunityIcons, FontAwesome5, Octicons } from "@expo/vector-icons";

import { HomeStackParamList, useBackButtonHandler } from "../../navigators"
import { DrawerScreenProps } from "@react-navigation/drawer"
import { useStores } from "../../models"
import { ITEM_STATUS } from "../../voolks/types";
import { getIconItem, getItemTitle, openPDF, getIconItemPTW } from "../../utils/itemCommonFunctions";
import { Linking, Platform } from "react-native";
import * as Storage from "../../utils/storage";
import { customApp } from "../../config";
import { base64Encode, calcularVelocidadConexion } from "../../utils/general";
import NetInfo from '@react-native-community/netinfo';

export const MeasurementScreen: FC<DrawerScreenProps<HomeStackParamList, "ItemsList">> = observer(function MeasurementScreen({ navigation, route }) {
    const fcName = "MeasurementScreen";
    console.log(fcName);
    const [viewActive, setViewActive] = React.useState<"charts" | "docs">("docs");
    const [isSwiping, setIsSwiping] = React.useState(false);
    const [search, setSearch] = React.useState("");
    const [showActionsheet, setShowActionsheet] = React.useState(false);
    const [idxChartSelected, setIdxChartSelected] = React.useState(0);
    const [showChartsSelect, setShowChartsSelect] = React.useState(false);
    const { formsStore, itemsStore, statusStore, authenticationStore } = useStores();
    const user = authenticationStore.userInfo.user;
    const [alertDialog, setAlertDialog] = React.useState(undefined);
    const [infoModal, setInfoModal] = React.useState(false);
    const [itemsPending, setItemsPending] = React.useState<boolean | undefined>(undefined);
    const [mustSync, setMustSync] = React.useState<boolean | undefined>(false);
    const swipeRows: any[] = [];
    let my_items: any = [];

    const [isConnected, setIsConnected] = React.useState<boolean | null>(null);

    let departments: any = [];
    React.useEffect(() => {

        const unsubscribe = NetInfo.addEventListener((state) => {
            setIsConnected(state.isConnected);
        });

        return () => {
            unsubscribe(); // Limpiar la suscripción
        };
    }, [])
    const setSpeedTest = (speed) => {
        statusStore.setFlashMessage("Velocidad de Conexion ".concat(speed, "Mbps"))
    }
    const leftButton = <></>;

    const goBack = () => {


    }

    useBackButtonHandler(goBack);

    const _openItem = async (form, department, itemSelected: any = undefined) => {
        console.log(fcName, "_openItem", "Init");
        if (itemSelected)
            await itemsStore.activateItem(itemSelected)
        if (itemsStore.itemActiveIDX != undefined) {
            await itemsStore.makePayloadFromFormPages(form.pages);
            navigation.navigate("ItemDetails", {
                itemStartedAt: new Date().getTime(),
                formId: form.id,
                deptId: department.id,
                forReview: itemsStore.itemActive.status != ITEM_STATUS.draft.toString(),
                taskId: undefined,
                pageActive: 0,
                rnd: new Date().getTime()
            })
        }
        console.log(fcName, "_openItem", "Fin");
    }

    const newItem = async (department, form) => {
        await itemsStore.new(form, undefined)
        _openItem(form, department);
    };


    const cloneItem = async (form, department, itemSelected: any = undefined) => {
        await itemsStore.new(form, undefined, itemSelected)
        _openItem(form, department);
    }

    const openItem = async (form, department, itemSelected: any = undefined) => {
        _openItem(form, department, itemSelected);
    }



    const removeItemsDialog = async (department, form, item: any = undefined) => {
        let lstIdx: number[] = [];
        let alert = { title: "", message: "" };
        if (item != undefined) {
            let itemIdx: number = itemsStore.getIDXofItem(item)
            if (itemIdx > -1) {
                lstIdx.push(itemIdx);
            }
            alert.title = `Borrar registro ${form.name}`;
            alert.message = `¿Estas seguro de borrar el registro  ${getItemTitle(form, item)}?`;
            setAlertDialog({
                ...alert,
                action: () => {
                    swipeRows.map(async (row) => {
                        await row?.closeRow();
                    });
                    lstIdx.sort((a, b) => { return b - a; }).forEach(idx => itemsStore.removeItem(idx));
                }
            })
        }
    }

    const renderItem = React.useCallback(
        (data, rowMap) => {
            const item = data.item;
            const idx = data.index;
            const department = departments.find((dept: any) => dept.id == data.item.deptId);
            if (department == undefined)
                return <></>;
            const form = (department.forms || []).find((form: any) => form.id == data.item.formId);
            if (form == undefined)
                return <></>;
            const _title = getItemTitle(form, item);
            if (search.trim().length > 0 && !(_title?.toUpperCase()?.includes(search.toUpperCase()) || form.name.toUpperCase()?.includes(search.toUpperCase())))
                return <></>;

            return (
                <VStack key={`SwipeRow${idx}`} borderBottomWidth={1} borderBottomColor={"$light300"} style={{ minHeight: 55, flexWrap: "wrap", backgroundColor: "white", justifyContent: "space-between", }}  >
                    <HStack py={10} width={"$full"} alignItems="center" bgColor={form.measurement.type == "point" ? "$white" : "$secondary0"}>
                        <Badge h={21} w={22} mb={6} mr={-23} bgColor="$transparent" zIndex={1} justifyContent="center"
                            variant="solid" alignSelf="flex-end" ><BadgeText size="2xs" color="$black" fontWeight="bold">{idx + 1}</BadgeText>
                        </Badge>
                        {getIconItem(item.status)}
                        <Pressable style={{ flexDirection: "row", alignItems: "center" }} onPress={() => openItem(form, department, item)} disabled={isSwiping}>
                            <VStack overflow="hidden" paddingHorizontal={3} maxWidth={(statusStore.windowDimensions.width - (statusStore.windowDimensions.width >= 1500 ? 400 : 80))}>
                                <Text textBreakStrategy="simple" size="md">{_title}</Text>
                                <Text textBreakStrategy="simple" size="xs">{department.name}  {form.name}</Text>
                            </VStack>
                            {getIconItemPTW(item.statusPTW)}
                        </Pressable>
                        <Spacer></Spacer>
                        <Pressable onPress={() => openItem(form, department, item)}><Icon name="arrow-forward" as={Ionicons} size="md" /></Pressable>
                    </HStack>
                </VStack>
            )
        }
        ,
        [departments]);

    const renderHiddenItem = React.useCallback((data, rowMap) => {
        const item = data.item;
        const department = departments.find((dept: any) => dept.id == item.deptId);
        if (department == undefined)
            return <></>;
        const form = (department.forms || []).find((form: any) => form.id == item.formId);
        if (form == undefined)
            return <></>;

        return (
            <View style={{
                alignItems: 'center',
                flexDirection: 'row', justifyContent: "center", marginTop: "auto"
            }}>
                <View style={{ minHeight: 45, flexWrap: "wrap", flexDirection: "row", justifyContent: "center", alignItems: "center" }}>
                    <Button2 borderRadius={0} width={55} height={55} p="$1" bgColor={"$violet500"}
                        variant="solid"
                        key="itemsStore-cloneItem"
                        onPress={() => cloneItem(form, department, item)}
                    >
                        <ButtonIcon as={Ionicons} name="copy" fontWeight="$extrabold" size="xl" color="#FFF" />
                    </Button2>
                </View>
                <View style={{ minHeight: 45, flexWrap: "wrap", flexDirection: "row", justifyContent: "center", alignItems: "center" }} >
                    <Button2 isDisabled={item.status != ITEM_STATUS.sent && item.id == undefined} borderRadius={0} width={55} height={55} p="$1" bgColor={"$success500"}
                        variant="solid"
                        key="itemsStore-openPDF"
                        onPress={() => openPDF(itemsStore, item)}
                    >
                        <ButtonIcon as={FontAwesome5} name="file-pdf" fontWeight="$extrabold" size="xl" color="#FFF" />
                    </Button2>
                </View>
                <View style={{ minHeight: 45, flexWrap: "wrap", flexDirection: "row", justifyContent: "center", alignItems: "center" }}>
                    <Button2 isDisabled={item.status != ITEM_STATUS.sent && item.id == undefined} borderRadius={0} width={55} height={55} p="$1" bgColor={"$yellow500"}
                        variant="solid"
                        key="itemsStore-openPDF"
                        onPress={() => openPDF(itemsStore, item, true)}
                    >
                        <ButtonIcon as={Ionicons} name="mail" fontWeight="$extrabold" size="xl" color="#FFF" />
                    </Button2>
                </View>

                <Spacer></Spacer>
                <View ><Button2 width={55} height={55} p="$1" bgColor={"$danger500"}
                    variant="solid"
                    key="itemsStore-removeItemsDialog"
                    onPress={() => removeItemsDialog(department, form, item)}
                >
                    <ButtonIcon as={Ionicons} name="trash-outline" fontWeight="$extrabold" size="xl" color="#FFF" />
                </Button2>
                </View>

            </View>
        )
    }, [departments]);

    const ItemsListRows: FunctionComponent = () => {
        return (
            <SwipeListView
                useFlatList={true} windowSize={100} removeClippedSubviews={true} initialNumToRender={15}
                onSwipeValueChange={({ value }) => {
                    if (value !== 0) setIsSwiping(true); // Detecta swipe activo
                    else setIsSwiping(false); // Resetea al finalizar el swipe
                }}
                data={my_items} style={{ width: "100%" }}
                renderItem={renderItem}
                leftOpenValue={170}
                rightOpenValue={-60}
                renderHiddenItem={renderHiddenItem}
            />
        )
    }

    const handleSearch = (text: string) => {

        setSearch(text);
        console.log(text);
    }


    const cancelRef = React.useRef();
    if (statusStore.status == "pending")
        return (<></>);

    const mySubscriptions = formsStore.mySubscriptions();
    let graphs: { id: number, name: string, deptId: number, deptName: string }[] = [];
    departments = mySubscriptions.subscriptions.reduce((lst, dept) => {
        const wfs = [];
        for (const form of dept.forms) {
            if (form.type == "measurement") {
                wfs.push(form)
                if (form.graph && graphs.findIndex(g => g.id == form.graphId)) {
                    graphs.push({ deptId: dept.id, deptName: dept.name, ...form.graph })
                }
            }
        }
        if (wfs.length > 0)
            lst.push({ "id": dept.id, "name": dept.name, forms: wfs })
        return lst;
    }, []);


    my_items = itemsStore.myItems().reverse().filter(item => {
        const department = departments.find((dept: any) => dept.id == item.deptId);
        if (department == undefined)
            return false;
        const form = (department.forms || []).find((form: any) => form.id == item.formId);
        if (form == undefined)
            return false;
        return true;

    }).sort((a, b) => a.status.localeCompare(b.status));
    const someToUpload =
        my_items.reduce((counter, item) => {
            return counter + (item.status == ITEM_STATUS.closed ? 1 : 0)
        }, 0);

    const getGraph = () => {
        const token = authenticationStore.userInfo.userToken;
        const url = `app/#/app/dashboard/departments/${graphs[idxChartSelected].deptId}/graphs/${graphs[idxChartSelected].id}`
        console.log("URL", url);
        return `${customApp.graphsUrl || customApp.baseUrl}app/loadfromApp.html#${base64Encode("token=".concat(token, "&user=", user.id, "&url=", url))}`;
    }
    const splitWindow= false; //Platform.OS == "web";
    return (<>
        <StatusBar backgroundColor={"#3700B3"} barStyle="light-content" />
        <Box safeAreaTop bg="primary.400" />
        <HStack bg="$primary400" px={0} py={0} justifyContent="space-between" alignItems="center" w="100%">
            <HStack alignItems="center" maxWidth={statusStore.windowDimensions.width - 140} >
                {leftButton}
                <VStack>
                    <Text color="white" fontSize={18} marginLeft={5} >
                        Certificación Digital
                    </Text>
                    <Text color="white" fontSize={10} marginLeft={5}>ult. sinc.: {new Date(mySubscriptions.lastSync).getYYYYMMddHHmmss()}</Text>
                </VStack>
            </HStack>
            <HStack>
               

            </HStack>
        </HStack>
        <HStack bg="white" flex={1} pt={4}>
            {(splitWindow || viewActive == "charts") ?
                <VStack px={0} py={0} my={0} mr={2} alignItems="center" w={splitWindow ? "70%" : "100%"} borderWidth={1} borderColor={"$light100"} >
                    <HStack bg={"$light100"} justifyContent="flex-start" alignItems="center" w="100%" p={3} mb={2} >
                        <Icon name="bar-chart-sharp" size={"lg"} as={Ionicons} color={"$light500"} my={3} mx={5} />
                        <Text fontWeight="bold" color="$light500">GESTION GLOBAL</Text>
                        <Spacer />
                        <Button2 variant="link" width={25} height={25} mr={20} p="$1"
                            key="Measurement-ChartChange"
                            onPress={async () => setShowChartsSelect(true)}>
                            <ButtonIcon as={MaterialIcons} name="settings" fontWeight="$extrabold" size="lg" color="$light500" />
                        </Button2>
                    </HStack>
                    <VStack justifyContent="center" >
                        {graphs.length > 0 && !isConnected ? <VStack alignItems="center"><Icon name="cloud-offline" size={"lg"} as={Ionicons} color={"$danger500"} m={20} /><Text>Sin acceso a la red</Text></VStack>
                            : (
                                Platform.OS != "web" ?
                                    <WebView
                                        source={{ uri: `${getGraph()}` }}
                                        originWhitelist={['*']}
                                        style={{ width: statusStore.windowDimensions.width, height: (statusStore.windowDimensions.height - 150), }}
                                    />
                                    :
                                    <WebViewForWeb
                                        startInLoadingState={true}
                                        source={{ uri: `${getGraph()}` }}
                                        style={{ overflow: 'hidden', marginTop: 0, height: (statusStore.windowDimensions.height - 150), width: statusStore.windowDimensions.width * 0.98 }}
                                        hideStatusBar={false}
                                        hideAddressBar={false}
                                        javaScriptEnabled={true}
                                        originWhitelist={['*']}
                                        hideActivityIndicator={false}
                                    />
                            )
                        }
                    </VStack>
                    <Actionsheet isOpen={showChartsSelect} onClose={() => setShowChartsSelect(false)}>
                        <ActionsheetBackdrop />
                        <ActionsheetContent>
                            <ActionsheetDragIndicatorWrapper>
                                <ActionsheetDragIndicator />
                            </ActionsheetDragIndicatorWrapper>
                            <Box w="100%" h={30} px={4} justifyContent="center">
                                <Text fontSize={16} color="$gray500" >
                                    Elija el Tablero que desea visualizar
                                </Text>
                            </Box>
                            <ScrollView ><Box w={statusStore.windowDimensions.width - 20} >
                                {graphs
                                    .map((g, idY) => <ActionsheetItem py="1" key={"btn_chart".concat(idY)} onPress={() => setIdxChartSelected(idY)}>
                                        <ActionsheetItemText>{g.name}</ActionsheetItemText>
                                    </ActionsheetItem>)
                                }</Box></ScrollView>
                        </ActionsheetContent>
                    </Actionsheet>
                </VStack>
                : <></>
            }
            {( splitWindow || viewActive == "docs") ?
                <VStack px={0} py={0} ml={2} alignItems="center" w={ splitWindow ? "29%" : "100%"} borderWidth={1} borderColor={"$light100"} >
                    <HStack bg={"$light100"} justifyContent="flex-start" alignItems="center" w="100%" p={3} mb={2}>
                        <Icon name="checklist" size={"lg"} as={Octicons} color={"$light500"} my={3} mx={5} /><Text fontWeight="bold" color="$light500">DOCUMENTOS EN CURSO</Text>
                        <Spacer></Spacer>
                        <VStack mr={1}>
                            {someToUpload > 0 ?
                                <Badge h={21} w={22} bg="$red600" borderRadius="$full" mb={-12} mr={0} zIndex={1}
                                    variant="solid" alignSelf="flex-end" ><BadgeText size="2xs" color="$white" fontWeight="bold">{someToUpload}</BadgeText>
                                </Badge> : null
                            }
                            <Button2 width={25} height={25} mr={20} p="$1" variant="link"
                                key="Measurement-upload"
                                onPress={async () => { Storage.removeItem("must_sync"); await itemsStore.upload({}); formsStore.getSubscriptions("measurement"); }}>
                                <ButtonIcon as={MaterialCommunityIcons} name="cloud-sync-outline" fontWeight="$extrabold" size="xl" color="$light500" />
                            </Button2>
                        </VStack>
                    </HStack>
                    <ItemsListRows></ItemsListRows>
                    <Spacer />
                    <HStack justifyContent="flex-end" width={"100%"}>
                        <Input w="75%" ml={5} variant="underlined">
                            <InputSlot mx={5} >
                                <InputIcon as={SearchIcon} />
                            </InputSlot>
                            <InputField placeholder={"Buscar..."} onChangeText={handleSearch} />
                        </Input>
                        <Spacer />
                        <Button2 margin={2} width={50} height={50} p="$1" borderRadius="$full" variant="solid" bgColor="$green600"
                            onPress={() => setShowActionsheet(true)}>
                            <ButtonIcon as={Ionicons} name="add" fontWeight="$extrabold" size="xl" color="#FFF" />
                        </Button2>
                        <Actionsheet isOpen={showActionsheet} onClose={() => setShowActionsheet(false)}>
                            <ActionsheetBackdrop />
                            <ActionsheetContent>
                                <ActionsheetDragIndicatorWrapper>
                                    <ActionsheetDragIndicator />
                                </ActionsheetDragIndicatorWrapper>
                                <Box w="100%" h={30} px={4} justifyContent="center">
                                    <Text fontSize={16} color="$gray500" >
                                        Indique tipo de documento a  crear:
                                    </Text>
                                </Box>
                                <ScrollView ><Box w={statusStore.windowDimensions.width - 20} >
                                    {departments.reduce((lst, dept, idX) => {
                                        let forms = [].concat(dept.forms);
                                        lst = lst.concat(
                                            forms.sort((a, b) => a.name.localeCompare(b.name))
                                                .map((f, idY) => <ActionsheetItem py="1" key={"btn_new".concat(idX, "-", idY)} onPress={() => newItem(dept, f)}>
                                                    <ActionsheetIcon as={MaterialCommunityIcons} name={f.measurement.type == "point" ? "format-list-checks" : "file-document-multiple"} />
                                                    <ActionsheetItemText>{f.name}</ActionsheetItemText>
                                                </ActionsheetItem>)
                                        );
                                        return lst;
                                    }, [])}</Box></ScrollView>
                            </ActionsheetContent>
                        </Actionsheet>
                    </HStack>

                </VStack>
                : <></>
            }
        </HStack>
        <HStack width={"100%"} backgroundColor="$light100" borderWidth={1} borderColor="$light300">
            <VStack >
                <HStack alignItems={"center"}>
                    <Button2 width={50} height={50} p="$1" variant="link" onPress={authenticationStore.signOut}>
                        <ButtonIcon as={MaterialIcons} name="logout" fontWeight="$extrabold" size="xl" color="$light500" />
                    </Button2>
                    <Text>{user?.firstName} {user?.lastName}</Text>
                    <Button2 width={50} height={50} p="$1" variant="link" onPress={() => navigation.navigate("Settings", {navigateTo:"MeasurementHome"})}>
                                    <ButtonIcon as={MaterialIcons} name="settings" fontWeight="$extrabold" size="xl" color="$light500" />
                                  </Button2>
                </HStack>
            </VStack>
            <Spacer></Spacer>
            {(!splitWindow && viewActive == "charts") ?
                <Button2 width={50} height={50} p="$1" variant="link" onPress={() => setViewActive("docs")}>
                    <ButtonIcon as={Octicons} name="checklist" fontWeight="$extrabold" size="xl" color="$light500" />
                </Button2>
                : <></>}
            {(!splitWindow && viewActive == "docs") ?
                <Button2 width={50} height={50} p="$1" variant="link" onPress={() => setViewActive("charts")}>
                    <ButtonIcon as={Ionicons} name="bar-chart-sharp" fontWeight="$extrabold" size="xl" color="$light500" />
                </Button2>
                : <></>}
        </HStack>


        {alertDialog == undefined ? <></> :
            <AlertDialog leastDestructiveRef={cancelRef} isOpen={alertDialog != undefined} >
                <AlertDialog.Content>
                    <AlertDialog.CloseButton />
                    <AlertDialog.Header>{alertDialog?.title}</AlertDialog.Header>
                    <AlertDialog.Body>{alertDialog?.message}</AlertDialog.Body>
                    <AlertDialog.Footer>
                        <Button ref={cancelRef} onPress={() => setAlertDialog(undefined)}>
                            Cancelar
                        </Button>
                        <Button colorScheme="red" ml={3} onPress={() => { alertDialog?.action(); setAlertDialog(undefined) }}>
                            Aceptar
                        </Button>
                    </AlertDialog.Footer>
                </AlertDialog.Content>
            </AlertDialog>
        }

        <AlertDialog leastDestructiveRef={cancelRef} isOpen={infoModal} onClose={() => setInfoModal(false)} size={"xl"}>
            <AlertDialog.Content>
                <AlertDialog.CloseButton />
                <AlertDialog.Header>Descripción iconos</AlertDialog.Header>
                <AlertDialog.Body >
                    <VStack >
                        <HStack borderBottomWidth={1} borderBottomColor={"gray.300"} py={1} alignItems="center" >
                            {getIconItem("draft")}
                            <Text ml="24px">Registro nuevo para ser completado.</Text>
                        </HStack>
                        <HStack borderBottomWidth={1} borderBottomColor={"gray.300"} py={1} alignItems="center" >
                            {getIconItem("draft")}
                            {getIconItemPTW("pending")}
                            <Text width={"80%"} >Registro creado por otro usuario y que debo completar</Text>
                        </HStack>
                        <HStack borderBottomWidth={1} borderBottomColor={"gray.300"} py={1} alignItems="center" >
                            {getIconItem("draft")}
                            {getIconItemPTW("finished")}
                            <Text>Permiso aprobado, debo cerrar trabajo</Text>
                        </HStack>
                        <HStack borderBottomWidth={1} borderBottomColor={"gray.300"} py={1} alignItems="center" >
                            {getIconItem("closed")}
                            <Text width={"90%"} ml="24px">Registro completado listo para subirse al servidor.</Text>
                        </HStack>
                        <HStack borderBottomWidth={1} borderBottomColor={"gray.300"} py={1} alignItems="center" >
                            {getIconItem("closed")}
                            {getIconItemPTW("pending")}
                            <Text width={"90%"}>Registro creado por otro usuario, que ya complete y debo subir al servidor</Text>
                        </HStack>
                        <HStack borderBottomWidth={1} borderBottomColor={"gray.300"} py={1} alignItems="center" >
                            {getIconItem("sent")}
                            {getIconItemPTW("inprogress")}
                            <Text width={"90%"} >Registro en el que ya participe, y que continua su workflow</Text>
                        </HStack>
                        <HStack borderBottomWidth={1} borderBottomColor={"gray.300"} py={1} alignItems="center" >
                            {getIconItem("sent")}
                            {getIconItemPTW("waiting")}
                            <Text width={"80%"} >Registro en el que debo participar, pero esta en una etapa previa</Text>
                        </HStack>
                        <HStack borderBottomWidth={1} borderBottomColor={"gray.300"} py={1} alignItems="center" >
                            {getIconItem("sent")}
                            {getIconItemPTW("finished")}
                            <Text width={"90%"} >Registro con aprobación completa, pendiente fin de trabajo</Text>
                        </HStack>
                        <HStack borderBottomWidth={1} borderBottomColor={"gray.300"} py={1} alignItems="center" >
                            {getIconItem("sent")}
                            {getIconItemPTW("closed")}
                            <Text>Trabajo terminado</Text>
                        </HStack>
                        <HStack borderBottomWidth={1} borderBottomColor={"gray.300"} py={1} alignItems="center" >
                            {getIconItem("sent")}
                            {getIconItemPTW("reject")}
                            <Text >Solicitud rechazada</Text>
                        </HStack>
                        <HStack borderBottomWidth={1} borderBottomColor={"gray.300"} py={1} alignItems="center" >
                            {getIconItem("sent")}
                            {getIconItemPTW("error")}
                            <Text >Error en Workflow</Text>
                        </HStack>

                        <View style={{ flexDirection: "row", flexWrap: "wrap", backgroundColor: "#226073", paddingHorizontal: 10 }}>
                            <Text color="white" bold={true} alignSelf="center">Probar velocidad de conexión</Text> <Spacer />
                            <Button2 width={50} height={50} p="$1" variant="link" onPress={() => calcularVelocidadConexion(setSpeedTest, statusStore)} >
                                <ButtonIcon as={MaterialIcons} name="speed" fontWeight="$extrabold" size="xl" color="$red500" />
                            </Button2>
                        </View>

                        <View style={{ flexDirection: "row", flexWrap: "wrap", backgroundColor: "#000", padding: 10 }}>
                            <Text color="white" bold={true}>INSTRUCTIVOS</Text>
                        </View>
                        <View style={{ flexDirection: "row", flexWrap: "wrap" }}>
                            <View style={{ flexDirection: "row", padding: 10, minWidth: "50%" }} >
                                <Icon
                                    onPress={() => Linking.openURL("https://drive.google.com/file/d/1IqRKrADc9SsXKRSVxVsDCV0q6LTxRItQ/view?usp=drive_link")} as={<FontAwesome5 name="file-pdf" />} size={"lg"} mr="2" color={"primary.400"} />
                                <Text>Documentación </Text>

                            </View>
                            <View style={{ flexDirection: "row", padding: 10, minWidth: "50%" }} >
                                <Icon onPress={() => Linking.openURL("https://drive.google.com/file/d/1RSbKyjnx3WyJUqhKuWGv_wDR82fcZvmy/view?usp=drive_link")}
                                    as={<MaterialIcons name="video-library" />} size={"lg"} mr="2" color={"secondary.400"} />
                                <Text>Anexo A1 </Text>

                            </View>
                            <View style={{ flexDirection: "row", padding: 10, minWidth: "50%" }} >
                                <Icon onPress={() => Linking.openURL("https://drive.google.com/file/d/19HqCPAnnEqwwc-p6tJ85imapRl-maxNv/view?usp=drive_link")}
                                    as={<MaterialIcons name="video-library" />} size={"lg"} mr="2" color={"secondary.400"} />
                                <Text>Anexo A1 Cadena de aprobación </Text>

                            </View>
                            <View style={{ flexDirection: "row", padding: 10, minWidth: "50%" }} >
                                <Icon onPress={() => Linking.openURL("https://drive.google.com/file/d/14eTY5WTNr1N3QAjL5GASaK5uJVSpnmwO/view?usp=drive_link")}
                                    as={<MaterialIcons name="video-library" />} size={"lg"} mr="2" color={"secondary.400"} />
                                <Text>Anexo A1 - Habilitante </Text>

                            </View>
                            <View style={{ flexDirection: "row", padding: 10, minWidth: "50%" }} >
                                <Icon onPress={() => Linking.openURL("https://drive.google.com/file/d/14eTY5WTNr1N3QAjL5GASaK5uJVSpnmwO/view?usp=drive_link")}
                                    as={<MaterialIcons name="video-library" />} size={"lg"} mr="2" color={"secondary.400"} />
                                <Text>Anexo A1 - Cierre del Trabajo </Text>
                            </View>
                            <View style={{ flexDirection: "row", padding: 10, minWidth: "50%" }} >
                                <Icon onPress={() => Linking.openURL("https://drive.google.com/file/d/14PTl4X_6SeYY35q8z9eNX94D02IYwxCN/view?usp=drive_link")}
                                    as={<MaterialIcons name="video-library" />} size={"lg"} mr="2" color={"secondary.400"} />
                                <Text>Anexo A2 </Text>
                            </View>
                            <View style={{ flexDirection: "row", padding: 10, minWidth: "50%" }} >
                                <Icon onPress={() => Linking.openURL("https://drive.google.com/file/d/1IZV7c2Mg0l_TtuKfciHpGaScE35XDiHa/view?usp=drive_link")}
                                    as={<MaterialIcons name="video-library" />} size={"lg"} mr="2" color={"secondary.400"} />
                                <Text>Anexo A3 </Text>
                            </View>
                            <View style={{ flexDirection: "row", padding: 10, minWidth: "50%" }} >
                                <Icon onPress={() => Linking.openURL("https://drive.google.com/file/d/1tNYLKltjtscGLj2eehmb4IVqmjzQA_zF/view?usp=drive_link")}
                                    as={<MaterialIcons name="video-library" />} size={"lg"} mr="2" color={"secondary.400"} />
                                <Text>Anexo A4 </Text>
                            </View>
                        </View>
                    </VStack>
                </AlertDialog.Body>
            </AlertDialog.Content>
        </AlertDialog>

    </>
    )
})
import { Box, Icon, Input, Text, TextArea } from 'native-base';
import React, { FC } from 'react';
import { ElementBase, IElementBaseProps, initializeElement } from '..';
import { Feather,  } from "@expo/vector-icons";

export const TextElement: FC<IElementBaseProps> = (({ ...props }) => {
    const {
        element,
        payload, checkAndSetPayload, errorList
    } = initializeElement(props);

    const handleChange = (value: string | undefined) => {
        value = value == undefined ? "" : value.toString();
        if (element.templateData.toChangeCase == "upperCase")
            value = value?.toUpperCase();
        if (element.templateData.toChangeCase == "lowerCase")
            value = value?.toLowerCase();        
        checkAndSetPayload(value);
    }
    return <ElementBase errors={errorList} element={element} forReview={props.forReview} link={payload.url}>
        {props.forReview ?
            <Text style={{ color: (element.templateData.textColor ? element.templateData.textColor : "#000") }}>
                {payload.value}
            </Text>
            :
            <Box>
                {(element.templateData.rowSpan == undefined || element.templateData.rowSpan == 1) ?
                    <Box><Input variant="unstyled" size={"md"}
                        InputLeftElement={element.type == "sendemail" ?<Icon as={Feather} mr="3" name="at-sign" size="md" />:<></>}
                        isReadOnly={element.templateData.readOnly ? true : false}
                        keyboardType={element.type == "sendemail" ? "email-address" : "default"}
                        color={element.templateData.textColor || "#000"}
                        fontWeight={element.templateData.hidden_label ? "bold":"normal"}
                        onChangeText={handleChange}
                        placeholder={element.templateData.placeholder || ""}
                        value={payload.value?.toString() || ""}
                    /></Box>
                    :
                    <Box><TextArea
                        variant="unstyled" size={"md"}
                        isReadOnly={element.templateData.readOnly ? true : false}
                        multiline={true}
                        numberOfLines={element.templateData.rowSpan}
                        keyboardType={element.type == "sendemail" ? "email-address" : "default"}
                        color={element.templateData.textColor || "#000"}
                        onChangeText={handleChange}
                        placeholder={element.templateData.placeholder || ""}
                        value={payload.value?.toString() || ""}
                        autoCompleteType="default" /></Box>
                }
            </Box>
        }
    </ElementBase>
});
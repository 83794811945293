import React from "react"
import { Icon } from "native-base"
import { Ionicons, MaterialIcons, MaterialCommunityIcons, Entypo } from "@expo/vector-icons";
import { Linking } from "react-native";
import { Platform } from 'react-native';
import * as Location from 'expo-location';
//import Geolocation from '@react-native-community/geolocation';
import Geolocation from 'react-native-geolocation-service';
import { formatNumber, urlFileToBase64 } from "./general";


export const getDisplayAndValue = (label: string) => {
  label = (label ?? "").toString()
  if (!label.includes('[object Object]')) {
    let idx: number = label.indexOf("|");
    if (idx > 0) {
      let display = label.substring(idx + 1);
      let value = label.substring(0, idx).replace("*", "");
      value = value == "" ? display : value;
      return { value, display, default: label.substring(0, idx).includes("*") }

    } else {
      return { display: label, value: label, default: false }
    }
  }
  return { display: "", value: null, default: false }
}

export const getGPSCoords = async (forceHighPrecision = 1000, statusStore = null): Promise<any> => {
  let { status } = await Location.getForegroundPermissionsAsync();
  console.log("getForegroundPermissionsAsync", status)
  if (status != "granted") {
    status = (await Location.requestForegroundPermissionsAsync()).status;
    if (status != "granted") throw new Error("Sin permiso al GPS");
  }
  console.log("getForegroundPermissionsAsync", status)
  if (Platform.OS == "web")
    return await Location.getCurrentPositionAsync({ accuracy: Location.Accuracy.Lowest });
  else {

    return await (new Promise((resolve, reject) => {
      let isFirstRead = true;
      const tryGetLocation = async () => {
        try {
          Geolocation.getCurrentPosition(location => {
            console.log("geolocation2", location);
            if (statusStore)
              statusStore.setFlashMessage(`Buscando satelites...precision ${location?.coords?.accuracy.toFixed(2)} metros,${forceHighPrecision < 1000 ? "mínima requerida metros :".concat(forceHighPrecision.toString()) : ""}`, 30000)
            if (location?.coords?.accuracy > forceHighPrecision) {
              isFirstRead = false;
              setTimeout(tryGetLocation, 500);
            }
            else
              resolve(location);
          }, err => {
            console.log("Error obteniendo coordenadas del GPS:", err);
            if (statusStore)
              statusStore.setFlashMessage(`Error obteniendo coordenadas del GPS: ${err.message}`);
            reject(err.message);
          }
            ,
            {
              timeout: 15000,
              maximumAge: (forceHighPrecision < 1000 && !isFirstRead) ? 0 : 5 * 60000,
              accuracy: { android: "high", ios: "best" }
              /*enableHighAccuracy: forceHighPrecision < 100 ? true : undefined */
            });
        } catch (err: Error) {
          if (statusStore)
            statusStore.setFlashMessage(`Error obteniendo coordenadas del GPS: ${err.message}`);
        }
      }

      tryGetLocation();

    }));
  }

}

export const getItemTitle = (form: any, item: any, AsColumns: boolean = false, withUrl: boolean = false) => {
  console.log("getItemTitle");
  let useAsTitleField: string[] = [];
  if (form.pages == undefined && Array.isArray(form)) {
    form = { pages: [{ fields: form }] } //Para cuando viene directamente la coleccion de campos.
  }
  try {
    useAsTitleField = form.pages.reduce((title: string[], page: any) => {
      title = title.concat(page.fields.reduce((title, field) => {
        /*  if (field.type == "autonumeric") 
         return title; */
        let itemPayload = item[field.id] || item.payload.get(field.id);
        if (field.type == "group") {
          let titleRet = field.nodes.reduce((title2, field) => {
            if (field.templateData && field.templateData.useAsTitle && itemPayload.value) {
              let value;
              switch (itemPayload.type) {
                case "date":
                  value = new Date(itemPayload.value).getYYYYMMdd();
                  break;
                case "timestamp":
                  value = new Date(itemPayload.value).getYYYYMMddHHmmss();
                  break;
                case "docs":
                  value = undefined;
                  break;
                case "number":
                  value = formatNumber(itemPayload.value,
                    field.templateData.forceDecimals ? (field.templateData.maxDecimalsCount || 2) : null,
                    field.templateData.thousandSeparator ? "," : "");
                  break;
                default:
                  value = getDisplayAndValue(String(itemPayload.value).trim()).display;
              }
              if (value !== undefined) {
                title2.push(withUrl && AsColumns ? { url: itemPayload.url, value } : value);
              }
            }
            return title2;
          }, []);
          if (titleRet.length > 0 && titleRet.join("") != "")
            return title.concat(titleRet);
        }
        if (field.templateData && field.templateData.useAsTitle && itemPayload && itemPayload.value) {
          let value;

          switch (itemPayload.type) {
            case "date":
              value = new Date(itemPayload.value).getYYYYMMdd();
              break;
            case "timestamp":
              value = new Date(itemPayload.value).getYYYYMMddHHmmss();
              break;
            case "docs":
              value = undefined;
              break;
            case "number":
              value = formatNumber(itemPayload.value,
                field.templateData.forceDecimals ? (field.templateData.maxDecimalsCount || 2) : null,
                field.templateData.thousandSeparator ? "," : "");
              break;
            default:
              value = getDisplayAndValue(String(itemPayload.value)).display;
          }

          if (value) {
            title.push(withUrl && AsColumns ? { url: itemPayload.url, value } : value);
          }

        }
        return title;
      }, []));
      return title;
    }, []);
  } catch (e) {
    //console.log(e);
    useAsTitleField = [];
  }
  let ret = [""]
  try {
    ret = useAsTitleField?.length > 0 ? useAsTitleField
      :
      [item._startedAt ?
        (new Date(item._startedAt).getYYYYMMddHHmmss()) //HORRIBLE OTROS FORMATOS 
        :
        (getDisplayAndValue(item[Object.keys(item)[0]].value).display || " ")];

  } catch (e) {
    console.log(e);
  }
  return AsColumns ? ret : ret.join(" ").trim()
}

export const getItemHeader = (form: any, retArray: boolean = false) => {
  console.log("getItemTitle");
  let useAsTitleField: string[] = [];
  if (form.pages == undefined && Array.isArray(form)) {
    form = { pages: [{ fields: form }] } //Para cuando viene directamente la coleccion de campos.
  }
  try {
    useAsTitleField = form.pages.reduce((title: string[], page: any) => {
      title = title.concat(page.fields.reduce((title, field) => {
        if (field.type == "group") {
          let titleRet = field.nodes.reduce((title2, field) => {
            if (field.templateData && field.templateData.useAsTitle) {
              title2.push(field.name || field.label);
            }
            return title2;
          }, []);
          if (titleRet.length > 0 && titleRet.join("") != "")
            return title.concat(titleRet);
        }
        if (field.templateData && field.templateData.useAsTitle) {
          title.push(field.name || field.label);
        }
        return title;
      }, []));
      return title;
    }, []);
  } catch (e) {
    //console.log(e);
    useAsTitleField = [];
  }
  let ret = [""]
  try {
    ret = useAsTitleField?.length > 0 ? useAsTitleField : [""];
  } catch (e) {
    console.log(e);
  }
  console.log("retArray")
  return retArray ? ret : ret.join(" ").trim()
}

export const getIconItem = (status: ("draft" | "closed" | "sent"), color: string = undefined, size: string = undefined) => {
  switch (status) {
    case 'draft':
      return (<Icon name="lock-open" size={size || "lg"} as={Ionicons} color={color || "opened.400"} />)
    case 'closed':
      return (<Icon name="lock-closed" size={size || "lg"} as={Ionicons} color={color || "closed.400"} />)
    case 'sent':
      return (<Icon name="cloud-done" size={size || "lg"} as={Ionicons} color={color || "done.400"} />)
  }
  return <></>;
}

export const getIconItemPTW = (status: string, color: string = undefined, size: string = undefined) => {
  switch (status) {
    case 'pending':
      return (<Icon name="pending" size={size || "lg"} as={MaterialIcons} color={color || "done.400"} />)
    case 'inprogress':
      return (<Icon name="progress-check" size={size || "lg"} as={MaterialCommunityIcons} color={color || "closed.400"} />)
    case 'waiting':
      return (<Icon name="md-warning-outline" size={size || "lg"} as={Ionicons} color={color || "warning.400"} />)
    case 'finished':
      return (<Icon name="checkbox-marked-circle" size={size || "lg"} as={MaterialCommunityIcons} color={color || "success.400"} />)
    case 'reject':
      return (<Icon name="cancel" size={size || "lg"} as={MaterialIcons} color={color || "black"} />)
    case 'error':
      return (<Icon name="robot-dead-outline" size={size || "lg"} as={MaterialCommunityIcons} color={color || "red.600"} />)
    case 'closing':
      return (<Icon name="progress-two" size={size || "lg"} as={Entypo} color={color || "closed.400"} />)
    case 'closed':
      return (<Icon name="marker-check" size={size || "lg"} as={MaterialCommunityIcons} color={color || "black"} />)
  }
  return <></>;
}

export const openPDF = async (itemsStore, item: any, toMail: boolean = false) => {
  let url = await itemsStore.getItemURLPDF(item.deptId, item.formId, item.id);
  if (url)
    Linking.openURL(!toMail ? url : encodeURI(`mailto:?&subject=Te han enviado un PDF - Field&body=Para ver el PDF sigue este link ${((url))}`));
}

export const isElementArchived = (fields, fieldId, isArchived) => {
  return fields.reduce((isArchived, field) => {
    if (isArchived) return isArchived;
    if (field.id == fieldId && field.isArchived) return true;
    if (field.nodes) return isElementArchived(field.nodes, fieldId, isArchived)
    return isArchived
  }, isArchived)
}

export const createDynamicParms = async (element: any, getPayloadElement: Function, parentId: string | undefined = undefined, recordIdx: number | undefined = undefined) => {
  try {
    let varsValue: any = {}
    for (const varname in element.integration.dynamicParams) {
      try {
        let payloadOfVar = getPayloadElement(element.integration.dynamicParams[varname], parentId, recordIdx);
        let value = payloadOfVar.value
        if (false && value && ["select", "multiple"].includes(payloadOfVar.type)) { //anulo esto porque antes no lo limpiaba a los caracteres extra
          value = getDisplayAndValue(value).value;
        } else if (value && payloadOfVar.type == "geo") {
          value = "".concat(value.coords.latitude, ",", value.coords.longitude);
        } else if (value && payloadOfVar.type == "simplelist") {
          value = "".concat(JSON.stringify(value));
        } else if (payloadOfVar.type == "docs" && value.length > 0) {
          value = await urlFileToBase64(value[0].uri)
        } else {
          value = value != undefined ? value.toString() : "";
        }
        if (value && value != "")
          varsValue[varname] = value;
      }
      catch (e) { }
    };

    for (const varname in element.integration.defaultParams) {
      varsValue[varname] = varsValue[varname] ?? element.integration.defaultParams[varname]
    }

    return varsValue;
  } catch (e) {
    return {}
  }
}

export const getDataFromIntegration = async (element: any,
  getPayloadElement: Function,
  parentId: string | undefined = undefined,
  recordIdx: number | undefined = undefined,
  getIntegration: Function) => {
  let dynamicParams = await createDynamicParms(element, getPayloadElement, parentId, recordIdx);
  let res = {};
  try {
    res = await getIntegration(element.integration.id, dynamicParams);
  } catch (e) {
    console.log(e);
  }

  return res;
}
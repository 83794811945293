import AsyncStorage from '@react-native-async-storage/async-storage' //'@react-native-community/async-storage';
import LZString from "lz-string"
import { Platform } from "react-native";
import { MMKV } from 'react-native-mmkv'
import { SimpleIDB } from './storageIndexDB'

export const DB = Platform.OS == "web" ? SimpleIDB : new MMKV();
const USE_MMKV = true;
if(Platform.OS=="web")
    SimpleIDB.initialize();

export const Storage = {
    // @ts-ignore
    setItem: (key, data) => USE_MMKV ? DB.set(key, data) : AsyncStorage.setItem(key, data),
    // @ts-ignore
    getItem: (key) => USE_MMKV ? DB.getString(key) : AsyncStorage.getItem(key),
    // @ts-ignore
    removeItem: (key) => USE_MMKV ? DB.delete(key) : AsyncStorage.removeItem(key),
    // @ts-ignore
    clearAll: () => USE_MMKV ?  DB.clearAll() : AsyncStorage.clear(),

}

const compress = (c: string) => { return Platform.OS == "web1" ? LZString.compressToUTF16(c) : c; };

const decompress = (c: string) => { return Platform.OS == "web1" ? LZString.decompressFromUTF16(c) : c; };

const keyNew = (key: string) => { return "v3-".concat(key) }
export const getItem = async (key: string): Promise<string | null> => {
    try {
        let keyOriginal = key;
        key = keyNew(key);
        let store = "";
        let numberOfParts: string | number = await Storage.getItem(key);
        if (typeof (numberOfParts) === 'undefined' || numberOfParts === null) {
            //migracion de data anterior si existe
            let originalValue = null;
            originalValue = await Storage.getItem(keyOriginal + "-part0");
            if (originalValue && originalValue != "") {
                //es version 165
                numberOfParts = await Storage.getItem(keyOriginal);
                // @ts-ignore
                numberOfParts = parseInt(numberOfParts);
                for (let i = 0; i < numberOfParts; i++) { store += await Storage.getItem(keyOriginal + "-part" + i); }
                await setItem(keyOriginal, JSON.parse(store))
                return await JSON.parse(store);
            } else {
                //es version 164 o anterior
                originalValue = await Storage.getItem(keyOriginal);
                if (originalValue || originalValue != null) {
                    await setItem(keyOriginal, originalValue)
                    return originalValue;
                } else
                    return null;
            }
        } else
            // @ts-ignore
            numberOfParts = parseInt(numberOfParts);
        for (let i = 0; i < numberOfParts; i++) { store += decompress(await Storage.getItem(key + "-part" + i)); }
        if (store === "")
            return null;

        return JSON.parse(store);
    }
    catch (error) {
        console.log("Could not get [" + key + "] from store.");
        console.log(error);
        return null;
    }

};

export const setItem = async (key: string, data: string) => {
    try {
        //console.log("setITEM", key);
        key = keyNew(key);
        await removeItem(key);

        const store = JSON.stringify(data).match(/.{1,100000}/g);
        store.forEach((part, index) => {
            //console.log(key + "-part",index);            
            Storage.setItem((key + "-part" + index), compress(part));
        });
        Storage.setItem(key, ("" + store.length));
        //console.log("setITEM", key,"FIN");
    }
    catch (error) {
        console.log("Could not save store : ", key, data.length);
        console.log(error.message);
        throw (error);
    }
};


export const clearAll = async () => {    
    Storage.clearAll();
}

export const loadJPGBase64 = async (key: string): Promise<string | null> => {
    try {
        return AsyncStorage.getItem(key);
    }
    catch (error) {
        console.log("Could not get [" + key + "] from store.");
        console.log(error);
        return null;
    }
};

export const saveJPGBase64 = async (key: string, data: string) => {
    try {
        await removeItem(key);
        console.log(navigator.storage.estimate())
    }
    catch (error) {
        console.log("Could not save store : ", key, data.length);
        console.log(error.message);
        throw (error);
    }
};

export const removeItem = async (key: string) => {
    try {
        key = keyNew(key);
        let numberOfParts: string | number = await Storage.getItem(key);
        if (typeof (numberOfParts) !== 'undefined' && numberOfParts !== null) {
            // @ts-ignore
            numberOfParts = parseInt(numberOfParts);
            for (let i = 0; i < numberOfParts; i++) { Storage.removeItem(key + "-part" + i); }
            Storage.setItem(key, ("0"));
        }
        Storage.removeItem(key);
    }
    catch (error) {
        console.log("Could not clear store : ");
        console.log(error.message);
    }
};

export async function load(key: string): Promise<any | null> {
    try {
        console.log("storage.load",1)
        const almostThere = await getItem(key)
        console.log("storage.load",2)
        return JSON.parse(almostThere)
    } catch {
        return null
    }
}

export async function loadLocal(key: string): Promise<any | null> {
    try {
        console.log("storage.loadLocal",1)
        const almostThere = await AsyncStorage.getItem(key)
        console.log("storage.loadLocal",2)
        return JSON.parse(almostThere)
    } catch {
        return null
    }
}

/**
 * Saves an object to storage.
 *
 * @param key The key to fetch.
 * @param value The value to store.
 */
export async function save(key: string, value: any): Promise<boolean> {
    try {
        //console.log("Storage","save", key);
        await setItem(key, JSON.stringify(value))
        return true
    } catch {
        return false
    }
}

export async function saveLocal(key: string, value: any): Promise<boolean> {
    try {
        //console.log("Storage","save", key);
        await AsyncStorage.setItem(key, JSON.stringify(value))
        return true
    } catch {
        return false
    }
}

export async function removeLocal(key: string): Promise<boolean> {
    try {
        await AsyncStorage.removeItem(key )
        return true
    } catch {
        return false
    }
}

export function localStorageSpace() { //10Mb Limit Of Chrome
    let allStrings = '';
    for (const key of Object.keys(window.localStorage)) {
        allStrings += window.localStorage[key];
    }
    return allStrings ? (3 + ((allStrings.length * 16) / (8 * 1024))).toFixed(0) + ' KB' : 'Empty (0 KB)';
};

